import request from './request'

const api = {
	// 获取列表
	lists: '/activity/lists',
	// 添加
	add: '/activity/add',
	// 删除
	dele: '/activity/del',
	// 编辑
	edit: '/activity/update',
	// 详情
	info: '/activity/read',
	// 村
	cunlist: '/village/lists',
}

export {
	request,
	api
}
